.bg-info {
	background-color: $primary !important;
}

.react-bootstrap-table {
	width: 100%;
	overflow-x: auto;
}

.react-bootstrap-table .table-bordered {
	margin-top: 1.25rem;
}

.react-bootstrap-table .table-bordered > tbody > tr > td {
	padding: 10px !important;
}

.react-bootstrap-table-pagination {
	margin-right: auto;
	margin-left: auto;
	margin-top: 30px;
}

.react-bootstrap-table th.sortable {
	cursor: pointer;
}

.react-bootstrap-table th.sortable label {
	display: block !important;
}

.react-bootstrap-table th.sortable label .form-control {
	height: calc(0.5rem + 1.25rem + 5px);
	padding: 0 0.5rem;
}

.no-link .react-bootstrap-table tbody tr td {
	padding: 10px !important;
	padding-left: 1.5rem !important;
	vertical-align: middle;
}